import React, { useState } from 'react';
import '../css/ginput.css';


import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';
import LoadingImg from '../images/loading.gif';

function Footer(props) {
    

  return (
    <div className='footer'>
        
        <div>Copyright 2025</div> 
        <div>For Compaint or Enquiry? Whatsapp <a href="https://wa.me/2347067152872" target='_blank'> 07067152872 </a> <FontAwesomeIcon icon={faPhone} />  </div>
        <div>Call Direct: <a href="tel:+2347067318895">07067318895</a></div>
        <div> <a href="https://web.twoleavesgames.com/" target="_blank">You want to know more? Click here</a></div>
      </div>
  )
}

export default Footer