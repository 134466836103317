import React, { useState , useEffect, useContext, useRef} from 'react';
import Logo from '../images/logo.png';
import '../css/pair.css'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faMessage,
    faUserAlt,
    faCheck} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';


function Pair() {
    const { profile, token, activepair, setActivePair, setToken } = useContext(ShareContext);
    const navigate = useNavigate();
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [cards, setCards]=useState([]);
    const [used, setUsed]= useState(0);
    const [unUsed,setUnUsed]=useState(10);
    const [pairB, setPairB]= useState({});
    const [turnBy, setTurnBy] =useState('');
    const [firstcall, setFirstCall]=useState(true);
    const [notePick,setNotePick] =useState('');
    const [winnerId, setWinner]=useState(null);
    const [sec, setSec] =useState(120);
    const [isEqual,setIsEqual]=useState(false);
    const [isTime, setIsTime]= useState(false);
    const [isTimerON, setTimerON]= useState(false);
    const [isonline, setIsonline]= useState(true);
    const [statusstr, setStatusstr]=useState("");
    var _sec=60;
    const count = useRef();
    const timoutcall =useRef(null);
    const timeload =useRef(null);
    //const exacttime=1000

    const colorA='#13B7FD';
    const colorB='#36981D';
    const color0="black";
    const callduration=2000;
    
    var intervalid=null;
    const onTimer=()=>{
        
      if(winnerId==null){
        if (timeload.current) {
          clearTimeout(timeload.current);
      }
        timeload.current = setTimeout(Load, callduration);
      }
        
    }

    const onExpireTime=()=>{ //for the up 
      
       
       count.current = count.current -1;
        setSec(count.current);
       if((count.current==80 || count.current==40 || count.current==10) && used==0){
          SwitchTurn();
       }
      //  console.log(`prev ${count.current} new `)
        if(count.current>=1 ){ //&& activepair.winnerId==null
            if (timoutcall.current) {
              clearTimeout(timoutcall.current);
          }
          timoutcall.current=  setTimeout(onExpireTime,1000);
        }
        
     }

    /*  useEffect(() => {
        onExpireTime();
       //for timer
     console.log('moment sec execute on *********************');
          }, [sec]); */

//console.log('the value of activepair');
console.log(activepair);

    useEffect(() => {
     
      if(activepair==null){
        //navigate('/dashboard')
        var acp=localStorage.getItem('activepair');
        if(acp==null){
          navigate('/dashboard')
        }else{
          var _acp=JSON.parse(acp);
          setActivePair(_acp);
          var str_token= localStorage.getItem("token");
          setToken(str_token);
        }
      }
      //setSec(120);
      //count.current=120;
       //LoadReports();

       if(cards.length==0){
        var c=[];
        for (let index = 0; index < 10; index++) {
            const cc={id:index+1,pickerid:0,score:0};
            c.push(cc);
        }
        setCards(c);
       }
        
       if(timoutcall.current==null){
          onExpireTime();
       }
       
      // setSec(119); //fist call
       console.log('********************first call for sec')
    
         }, [activepair]);


   useEffect(() => {

           if(winnerId!=null){
            //ended
            if(timoutcall.current!=null){
              //cancel the timeer
              clearTimeout(timoutcall.current);
            }
             setTimeout(onWinner,5000); //wait for 20 second
           }

   }, [winnerId]);

useEffect(() => {

         console.log(`****** the isTimerON Status Change ${isTimerON}. current time ticker is ${count.current}`);
         if(!isTimerON && count.current>0){
            if (timeload.current) {
              clearTimeout(timeload.current);
            }
          timeload.current = setTimeout(Load, callduration);
         }

   }, [isTimerON]);

         useEffect(() => {
     
           if(cards.length>0 && firstcall && activepair!=null){
          //  console.log('call from firstcall and card has value');
            if((activepair.pairB.id==profile?.id && activepair?.turnBy=="B") || (activepair?.pairA?.id==profile?.id && activepair?.turnBy=="A")){
                console.log('do not need to start a timer' );
                setTimerON(false);
             }else{
                 //intervalid = setInterval(Load, callduration);
                 console.log('starting timer........ its your oponent turn or time end and timer has to be running' );
                    onTimer();
                    if(!isTimerON){

                      setTimerON(true);
                    }
              }
     
             Load();
           }
     
             
            
             return () => clearInterval(intervalid);
              }, [cards]);


    useEffect(() => {
     
     
     //  console.log('content of cards before update')
       //     console.log(cards);
            if(cards.length> 0){
                console.log('notepick '+notePick);
                const pairs =notePick==null?[]: notePick?.split(';');
            const returncards=[];
           // console.log('content of cards before update')
          //  console.log(cards);
            var _cards=[...cards];
          //  console.log(_cards);
                // Loop through each pair and construct the object
                for (let i = 0; i < pairs.length; i++) {
                    // Ignore empty strings (like the last split)
                    if (pairs[i] !== '') {
                        // Split each pair by colon to get key and value
                        //id:cardindex+1,pickerid:profile.id,score: data.num
                        const [id, score,pickerid] = pairs[i].split(':');
                        _cards[parseInt(id)-1]={id,pickerid,score};
                        // Add an object with key and value to the array
                        returncards.push({ id, score,pickerid });
                    }
                }
                setUsed(returncards.length);
                setUnUsed(cards.length-returncards.length);
                if(isEqual){
                  var d2=[{id:cards.length,pickerid:0,score:0}, {id:cards.length+1,pickerid:0,score:0}];
                  //setCards([...cards,d2]); 
                  _cards=[..._cards,d2];
                  setIsEqual(false);
                }
                setCards(_cards);
             //   console.log('content of cards after update')
              //  console.log(_cards);
            }
            
            if(activepair!=null){
               if ( (activepair.pairA.id==profile?.id && turnBy=="B") || (activepair.pairB.id==profile?.id && turnBy=="A")){
            console.log('starting a timer************************* on turnby');
            onTimer();
            if(!isTimerON){
              setTimerON(true);
            }
           }else{
            console.log('stoping a timer*****************************, ignore the call');
            //clearInterval(intervalid);
            setTimerON(false);
           }
            }

         }, [turnBy, firstcall]);

    const Load=async()=>{
      
      if(activepair==null)
        return;

      if(!isTime){
        LevelTimeCheck(activepair.levelId);
      }
      
        var urls=`${Constants.rooturl}/game/checkturn/${activepair?.id}`;
        try {
           // setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
            setIsonline(true)
           // setLoading(false);
           // console.log(data);
            if (data.code === 0) {
                //reconstruct the list
                //code = 0,pair.TurnBy, pair.WinnerId, pair.PickNote
                if(firstcall || turnBy!=data.turnBy || data.winnerId!=null){ //only update if what you have is different
                   
                    setTurnBy(data.turnBy);
                    setNotePick(data.pickNote);
                    setActivePair({...activepair, pairBscore:data.pairBscore,pairAscore:data.pairAscore});
                    setWinner(data.winnerId);
                    setIsEqual(data.isEqual);
                    setTimerON(false);
                }else{
                    //console.log('calling timer again in Load');
                    onTimer();
                    if(!isTimerON){
                      setTimerON(true);
                    }
                }
                
                setFirstCall(false); //that way second option is called
                
            }if (data.code === -10){
              navigate("/dashboard");
            }
            else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
            setIsonline(false);
          }
     }

     const SwitchTurn=async()=>{
      if(activepair==null)
        return;

      var urls=`${Constants.rooturl}/game/switchpair/${activepair?.id}`;
      try {
         // setLoading(true);
          const resp = await fetch(urls, { 
            method: 'Post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
         // setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //switch compted
          }else if(data.code==-2){
            //go back to homepage
             //navigate("/dashboard"); ignore
          }
          else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

   const LevelTimeCheck=async(levelid)=>{
   
    if(isTime)
      return;

    var urls=`${Constants.rooturl}/game/leveltimecheck/${levelid}`;
    try {
        const resp = await fetch(urls, { 
          method: 'Post',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        console.log(data);
        console.log('check level time');
        console.log(data);
        if (data.code === 0) {
          
          if(data.sec<1){
            setSec(0);
            count.current=0;
          }else{
            setSec(data.sec);
            count.current=data.sec;
          }
          
          setIsTime(true);
        }
        else {    
          console.error(data.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error); 
      }
 }


     const LoadReports=async()=>{
      
      var urls=`${Constants.rooturl}/customer/activities/${profile.id}`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              
              
          }else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

   const onPick=async(cardindex)=>{
    
    if(cards[cardindex].pickerid>0){
        //card already picked
        console.log('card already picked, pick another');
        setStatusstr('card already picked, pick another');
        return;
    }else{
      setStatusstr('');
    }
      setLoading(true);
      const totalPicked =used+1  ;//cards.filter(obj => obj.score > 0).length;
      try {
        var v={Pickno:(cardindex+1), lastcard:false,Turn:turnBy, totalPicked:(totalPicked)}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game/pick/${activepair.id}`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': token,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0 ) {
           //get //code = 0, num, pair.WinnerId, pair.TurnBy
           setTurnBy(data.turnBy);
           //update the value in //id:index+1,pickerid:0,score:0
           var _cards=[...cards];
           _cards[cardindex]={id:cardindex+1,pickerid:profile.id,score: data.num};

           if(data.isEqual){
            var d2=[{id:cards.length,pickerid:0,score:0}, {id:cards.length+1,pickerid:0,score:0}];
            //setCards([...cards,d2]); 
            _cards=[..._cards,d2];
          }
           setCards(_cards);
           setUsed(used+1);
            setUnUsed(unUsed-1);
           //Load();
          //  intervalid = setInterval(Load, callduration);
          setActivePair({...activepair, pairBscore:data.pairBscore,pairAscore:data.pairAscore})
          setWinner(data.winnerId);
          
            onTimer();// so it can start calling
            if(!isTimerON){
              setTimerON(true);
            }

        }else if(data.code === 1){
          setStatusstr('card already picked on system. Pick another');
          setTurnBy(data.turnBy);
          setNotePick(data.pickNote);
          setActivePair({...activepair, pairBscore:data.pairBscore,pairAscore:data.pairAscore});
          setWinner(data.winnerId);
        } 
      } catch (error) {
        setLoading(false);
        console.error(error);
        setStatusstr('Somehow cannot connect. Screenshot and send to admin');

       // setErr(Constants.serverErr);
       // onMove();
      }

     }

  const onWinner=()=>{
        setActivePair({...activepair, winnerId});
        clearTimeout(timeload.current);
        navigate('/customer/game/win');
  }   

  return (
 
    activepair!=null &&  <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
        <div className='homebar'>
        
        <div className='logoside'>
          <img src={Logo}/> 
          <span>TWO LEAVES GAMES</span>
        </div>
        <div style={{display:'flex'}}>
            <div class='timeIndicator'><p>
                <span>Time</span><span>{Constants.timestr(sec)} </span>
            </p>
                
            </div>
        </div>
        <div className='menu'>
          <ul>
            
            <li ><Link to="/"><FontAwesomeIcon icon={faMessage}  /></Link></li>
            <li ><Link to="/"><FontAwesomeIcon icon={faUser}  /></Link></li>
            
          </ul>
        </div>
      </div>
<div className='cusBody'> 
    
    
    <div className='pairback'>
       <div className='pairmember'>
            <div>
                <div className='avatar'>
                    <FontAwesomeIcon icon={faUserAlt} style={{fontSize:40, color:colorA}}  />
                </div>
            
            <p className='playername'>{activepair?.pairA?.id==profile?.id?'You':activepair.pairA.firstname}</p>
            <div>
                <span className='playerphone'>{activepair?.pairA?.phone}</span><br/>
                <span className='playersocre'>Score: {activepair.pairAscore}</span>
            </div>
           {turnBy=='A' && <p className='turn'>Turn A</p>} 
            </div>
            <div className='playermiddle'>
            <p><span>Used Card:</span><span>{used>10?10:used}</span></p>
            <p><span>Unused Card:</span><span>{unUsed}</span></p>
            
        </div>
        <div>
                <div className='avatar'>
                    <FontAwesomeIcon icon={faUserAlt} style={{fontSize:40, color:colorB}}  />
                </div>
            
                <p className='playername'>{activepair.pairB.id==profile?.id?'You':activepair.pairB.firstname}</p>
            <div>
                <span className='playerphone'>{activepair.pairB.phone}</span><br/>
                <span className='playersocre'>Score: {activepair.pairBscore}</span>
            </div>
           {turnBy=='B' && <p className='turn'> Turn B</p>} 
            </div>
       </div>
       {winnerId!=null && <h2 className='blink_me'>Game Over!</h2>}
      {sec<=0 &&  <p className='blink_me gametimenote'>Note: Game time has passed. Admin can end any moment. You will loose the game if it is your turn</p> }
<div>{isonline?<strong style={{color:'green', fontWeight:'bold'}}>Online</strong>:<strong style={{color:'red', fontWeight:'bold'}}>Offline</strong>}</div>
{statusstr!='' && <div style={{color:'red', fontWeight:'bold'}}>{statusstr}</div>}
       <div className='cards'>
       {cards?.map((card, idx) =>{return (
                    <div className='card' key={idx} style={{backgroundColor:(card.pickerid==activepair.pairA.id? colorA:(card.pickerid==activepair.pairB.id? colorB:color0))}} onClick={()=>onPick(idx)}>
                       <div className='cardtop'>
                        <span>Card: {idx+1} </span>
                        {card.pickerid>0 && <FontAwesomeIcon icon={faCheck}  />}
                        </div> 
                        {card.pickerid>0 &&  <div className='cardpick'>
                            <span>{card.score}</span>
                        </div>}
                       
                        

                    </div>
                  )})}
       </div>

    </div>
    <div style={{textAlign:'center'}}>
          <button className='btn_pry_red' onClick={()=>navigate("/dashboard")}>Close</button>
        </div>
    </div>
         
    </div>

  )
}

export default Pair