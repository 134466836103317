import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ShareContext } from './context/sharecontext';
import { useState, useEffect } from 'react';
import Home from './customer/home';
import Login from './customer/login';
import Register from './customer/register';
import Dashboard from './customer/dashboard';
import Interest from './customer/interest';
import Pair from './customer/pair';
import Win from './customer/win';
import AdminLogin from './admin/adminLogin';
import AdminUser from './admin/adminuser';
import AdminDashboard from './admin/admindashboard';
import Customers from './admin/customers';
import Games from './admin/games';
import Leaders from './admin/leaders';
import Profile from './admin/profile';
import Users from './admin/users';
import CustomerDetails from './admin/customerDetails';
import GameDetails from './admin/gameDetails';
import Account from './customer/account';
import Downline from './customer/downline';
import FundWallet from './customer/fundwallet';
import ReturnPayment from './customer/returnpaystack';
import AdminAccount from './admin/account';
import Faq from './customer/Faq';
import ForgetPassword from './customer/forgetpassword';
import ResetUser from './customer/resetuser';
import WithdrawWallet from './customer/withdraw';
import Footer from './components/footer';



function App() {

  const [profile, setProfile]=useState(null);
  const [adminProfile, setAdminProfile]=useState(null);
  const [raw, setRaw]=useState(null);
  const [activeType, setActiveType]=useState(null);
 const [token, setToken] = useState(null); 
  const [adminToken, setAdminToken] = useState(null);

  const [activepair, setActivePair]= useState(null);
  const [activelevel, setActiveLevel]= useState(null);
  useEffect(() => { 
    // Constants.seo('','');
    if(profile==null){
      //load from storage
      var pro=localStorage.getItem('profile');
      console.log('from app call');
      console.log(pro);
      if(pro!=null){
          var proo=JSON.parse(pro);
          setProfile(proo);
      }
    }
 }, []);
  return (
    <div className="App">
     <ShareContext.Provider value={{profile,setProfile, raw, setRaw, activeType, setActiveType, token, setToken,activepair, setActivePair, activelevel, setActiveLevel,
      adminProfile, setAdminProfile, adminToken, setAdminToken}}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/login" exact element={<Login/>} />
          <Route path="/user/forget" exact element={<ForgetPassword/>} />
          <Route path="/user/reset/:resetkey" exact element={<ResetUser/>} />
          <Route path="/register" exact element={<Register/>} />
          <Route path="/leader/:code" exact element={<Register/>} />
          <Route path="/dashboard" exact element={<Dashboard/>} />
          <Route path="/customer/account" exact element={<Account/>} />
          <Route path="/faq" exact element={<Faq/>} />
          <Route path="/leader/downline" exact element={<Downline/>} />
          <Route path="/customer/game/interest" exact element={<Interest/>} />
          <Route path="/customer/game/pair" exact element={<Pair/>} />
          <Route path="/customer/game/win" exact element={<Win/>} />
          <Route path="/customer/fundwallet" exact element={<FundWallet/>} />
          <Route path="/customer/withdraw" exact element={<WithdrawWallet/>} />
          <Route path="/customer/payment/confirm/:refid" exact element={<ReturnPayment/>} />
          
          
          <Route path="/admin/login" exact element={<AdminLogin/>} />
          <Route path="/admin/user" exact element={<AdminUser/>} />
          <Route path="/admin/dashboard" exact element={<AdminDashboard/>} />

          <Route path="/admin/customers" exact element={<Customers/>} />
          <Route path="/admin/customers/:id" exact element={<CustomerDetails/>} />
          <Route path="/admin/games" exact element={<Games/>} />
          <Route path="/admin/games/:id" exact element={<GameDetails/>} />
          <Route path="/admin/leaders" exact element={<Leaders/>} />
          <Route path="/admin/profile" exact element={<Profile/>} />
          <Route path="/admin/users" exact element={<Users/>} />
          <Route path="/admin/account" exact element={<AdminAccount/>} />
        </Routes>
        <Footer/>
      </Router>
     </ShareContext.Provider>
    </div>
  );
}

export default App;
