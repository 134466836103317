import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faRefresh} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GInput from '../components/gInput';


function FundWallet() {
    const navigate = useNavigate();
    const { profile, token, setProfile, setActivePair, setActiveLevel,setToken } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [amount, setAmount] = useState(0);




    console.log(profile);
    useEffect(() => {
     
      if(profile==null){
        var str_p=localStorage.getItem("profile" )
       var str_token= localStorage.getItem("token");
       if(str_p==null){
        navigate("/login");
       }
       else{
        var s_profile=JSON.parse(str_p);
        setProfile(s_profile);
        setToken(str_token);
       }
        
       
      }
         }, []);
    

         const onCancel=()=>{
          navigate("/dashboard");
         }   

 
  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
    <div className='title'>
      <div>
        <h3>Fund Wallet</h3>
      
      </div>
        
      
    
    </div>
    

  
    <div className='innerBox'>
    <form className='form-inp' action='/paystack.html'>
    <h2>Pay Via Payment Gateway</h2>
    <p>Amount to Fund</p>
         <GInput label="Amount to Deposit" type='number' min={0} value={amount} onChange={(e)=>setAmount(e.target.value)}/>
<div className="success-box">
         <h2>Amount {Constants.formatCurrency(amount) }</h2>
             <input type='hidden' name="name" value={profile?.firstName}  />
             <input type='hidden' name="email" value={profile?.email==null?`${profile?.phone}@twoleavesgames.com`:profile?.email}   />
             <input type='hidden' name="phone" value={profile?.phone}   />
             <input type='hidden' name="returnurl" value={`${Constants.baseurl}/customer/payment/confirm/`}   />
             <input type='hidden' name="amount" value={amount} />
             <input type='hidden' name="docref" value={`${profile?.id}`} />
             <input type='hidden' name="userid" value={`${profile?.id}`} />
           <button className='btn_primary'  type='submit'>Fund Now</button>  <button className='red' onClick={onCancel} type='button'>Cancel</button> 
       </div>     
         </form>  
         <div>
          <h3>You want to do direct bank payment?</h3>
          <h2> Account Name: Two leaves Investment ltd</h2>
          <h2>Account No: 1011217924 </h2>
          <h2>Bank: UBA</h2>
         </div>
    </div>
    </div>
  
     
        
    </div>

  )
}

export default FundWallet